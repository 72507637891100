<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-3 col-md-4 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <custom-multi-select
                  :model.sync="filters.supplier_company_id"
                  :options="supplierCompanyOptions"
                  :item-per-row="1"
                  :is-inline="false"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :title="$t('waybill.current_account')"
                  input-width="150px"
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="filters.company_id"
                  :options="customerOptions"
                  :title="$t('order.customer')"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="150px"
                  name="order_company_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="filters.user_id"
                  :not-list="false"
                  :options="userOptions"
                  :title="$t('report.customer_representative')"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="150px"
                  name="customers"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="filters.order_id"
                  :not-list="false"
                  :options="orderOptions"
                  :title="$t('general.order')"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="150px"
                  name="order_id"
                ></custom-multi-select>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-5">

        </div>
        <div class="col-7">
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <FireReportChart
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :onClickButton="onClickButton"
                :company-id="filters.company_id"
                :supplier-company-id="filters.supplier_company_id"
                :order-id="filters.order_id"
                :user-id="filters.user_id"
              ></FireReportChart>
            </template>
          </dashboard-box>
        </div>
      </div>

      <dashboard-box :title="$t('esc.esc_list')">
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :page.sync="page"
              :perPage.sync="perPage"
              :striped="true"
              :infiniteId="infiniteId"
              tableVariant="''"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              @onClickView="onClickView"
            ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import FireReportChart from "./FireReportChart";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/fireReport.module";

import { GET_ITEMS } from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_TIME_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
  LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "loadingDeadlineReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    FireReportChart,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    formattedReport() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      let temp = [];
      this.items.data.forEach((item) => {
        let cuttingRate = 0;
        if (
          item.expense_cut_total_amount > 0 &&
          item.packing_list_total_amount > 0 &&
          item.expense_cut_total_amount
        ) {
          cuttingRate =
            (Number(item.expense_cut_total_amount) -
              Number(item.packing_list_total_amount)) /
            Number(item.expense_cut_total_amount);
        }

        let overloadingQuantity = 0;
        if (item.packing_list_total_amount > 0 && item.order_total_amount > 0) {
          overloadingQuantity =
            Number(item.packing_list_total_amount) - Number(item.order_total_amount);
        }

        let overloadingRate = 0;

        if (item.packing_list_total_amount > 0 && item.order_total_amount > 0) {
          overloadingRate =
            (Number(item.packing_list_total_amount) - Number(item.order_total_amount)) /
            Number(item.order_total_amount);
        }

        let manufacturingDefectQuantity =
          Number(item.expense_cut_total_amount) - Number(item.packing_list_total_amount);

        let manufacturingDefectRate = 0;
        if (
          Number(item.packing_list_total_amount) > 0 &&
          Number(item.expense_cut_total_amount) > 0
        ) {
          manufacturingDefectRate =
            manufacturingDefectQuantity / item.expense_cut_total_amount;
        }
        temp.push({
          company_name: item.company_name,
          order_total_amount: Number(item.order_total_amount).toLocaleString("tr-TR"),
          packing_list_total_amount: Number(
            item.packing_list_total_amount
          ).toLocaleString("tr-TR"),
          expense_cut_total_amount: Number(item.expense_cut_total_amount).toLocaleString(
            "tr-TR"
          ),
          cutting_rate: cuttingRate ? cuttingRate.toFixed(2).replace(".", ",") + "%" : "",
          overloading_quantity: overloadingQuantity.toLocaleString("tr-TR"),
          overloading_rate: overloadingRate ? overloadingRate.toFixed(2) + "%" : "",
          manufacturing_defect_quantity: manufacturingDefectQuantity.toLocaleString(
            "tr-TR"
          ),
          manufacturing_defect_rate: manufacturingDefectRate
            ? manufacturingDefectRate.toFixed(2) + "%"
            : "",
        });
      });
      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      userOptions: [],
      sampleStatuses: [],
      fieldsToViewEdit: [],
      customerOptions: [],
      search: null,
      paginate: [],
      orderSizeAndColorExportDateBodySizes: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/fire-report",
      currencyRateOptions: [],
      companies: [],
      orderOptions: [],
      tmpOrderData: [],
      tmpPackingListData: [],
      supplierCompanyOptions: [],
      moment: moment,
      onClickButton: false,
      fields: [
        {
          key: "company_name",
          label: this.$t("report.customer"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "order_total_amount",
          label: this.$t("report.order_total_amount"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "packing_list_total_amount",
          label: this.$t("report.packing_list_total_amount"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "expense_cut_total_amount",
          label: this.$t("report.expense_cut_total_amount"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "cutting_rate",
          label: this.$t("report.cutting_rate"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "overloading_quantity",
          label: this.$t("report.overloading_quantity"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "overloading_rate",
          label: this.$t("report.overloading_rate"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "manufacturing_defect_quantity",
          label: this.$t("report.manufacturing_defect_quantity"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "manufacturing_defect_rate",
          label: this.$t("report.manufacturing_defect_rate"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    viewExportDateBodySize(exportDate) {
      this.orderSizeAndColorExportDateBodySizes = [];
      this.orderSizeAndColorExportDateBodySizes = exportDate;
    },
    sumAmountOrderSizeAndColorExportDate(exportDate) {
      let tmpExportDate = _.cloneDeep(exportDate);
      let sumAmount = 0;
      Object.entries(tmpExportDate.body_sizes).forEach((bodySize, key) => {
        sumAmount += Number(bodySize[1].amount);
      });
      return sumAmount;
    },
    closeModalAndCancel(name) {
      this.closeModalByName(name);
      this.orderSizeAndColorExportDateBodySizes = [];
    },
    onViewPackingListData(data) {
      this.tmpPackingListData = data;
    },
    onClickView(payload) {
      this.tmpOrderData = [];
      this.tmpPackingListData = [];
      this.tmpOrderData = _.find(this.items.data, { id: payload });
      this.orderSizeAndColorExportDateBodySizes = [];
      this.openModalByName("detailReport");
    },
    filterResult() {
      localStorage.setItem(
        LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      if (this.filters.supplier_company_id) {
        this.$set(
          filters,
          "supplier_company_id",
          JSON.stringify(this.filters.supplier_company_id)
        );
      }
      if (this.filters.company_id) {
        this.$set(filters, "company_id", JSON.stringify(this.filters.company_id));
      }
      if (this.filters.order_id) {
        this.$set(filters, "order_id", JSON.stringify(this.filters.order_id));
      }
      if (this.filters.user_id) {
        this.$set(filters, "user_id", JSON.stringify(this.filters.user_id));
      }

      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            if ($state) {
              this.infiniteId++;
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
    },
    setup() {
      let self = this,
        promises = [];
      //0
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );
      //1
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list",
        })
      );
      //2
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/supplier-companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/users/customer representative",
          acceptPromise: true,
          filters: {},
        })
      );

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.customerOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "name"
          );
        }

        if (results[1].status) {
          self.orderOptions = results[1].data;
        }

        if (results[2].status) {
          self.supplierCompanyOptions = self.convertArrayToObjectByKey(
            results[2].data,
            "id",
            "name"
          );
        }
        if (results[3].status) {
          self.userOptions = self.convertArrayToObjectByKey(
            results[3].data,
            "id",
            "name"
          );
        }
      });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.loading_deadline_report") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
