<template>
  <div>
    <div class="graphic-container">
      <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
        width="100%"
      ></apexchart>
    </div>
    <div class="row m-0 p-0 ml-5 mb-4 mt-4 d-flex align-items-center justify-content-start">
      <div
        class="info-text-background p-1 d-flex mx-2 text-center justify-content-center align-items-center"
      >
        <div class="box incerment-box mx-2"></div>
        - % DEĞER ALTINDA
      </div>

      <div
        class="info-text-background p-1 d-flex mx-2 text-center justify-content-center align-items-center"
      >
        <div class="box plus-box mx-2"></div>
        + % DEĞER ÜSTÜNDE
      </div>
    </div>
  </div>
</template>
<script>
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "PurchasingInstructionReportChart",
  props: [
    "startDate",
    "endDate",
    "companyId",
    "onClickButton",
    "supplierCompanyId",
    "orderId",
    "userId",
  ],
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    chartOptions() {
      let length = 500;
      if (this.tmpSeries.length <= 15) {
        length = 750;
      } else {
        length = length + (this.tmpSeries.length - 7) * 30;
      }
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 350,
          width: length,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "50%",
            barHeight: "20%",
            barPadding: 2, // Sütunlar arasındaki boşluk (örneğin, 4 piksel)
            barWidth: "25%", // Sütunların genişliği yüzde olarak belirtilir (örneğin, %80 genişlik)
            colors: {
              ranges: [
                {
                  from: -100,
                  to: 0,
                  color: "#F63535",
                },
              ],
            },
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: _.cloneDeep(this.chartCategories),
          tickPlacement: "on",
          trim: false,
          minHeight: 200,
          rotate: -90,
          labels: {
            rotate: -90,
            style: {
              fontSize: "12px",
              display: "inline-block",
              overflow: "hidden",
              fontWeight: 500,
            },
            title: {
              text: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-xaxis-title",
              },
            },
          },
        },
        yaxis: {
          title: {
            text: _.cloneDeep(this.chartText),
          },
          labels: {
            formatter: (val) => {
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            },
          },
          formatter: function (val) {
            return val.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            },
          },
        },
      };
      return tmpChartOptions;
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartCategories: [],

      tmpSeries: [],
      series: [],
      chartText: "",
    };
  },

  methods: {
    getItems() {
      this.series = [];
      this.chartCategories = [];
      this.chartCategories = [];
      this.tmpPackingSeries = [];
      let filters = {
        start_date: this.startDate,
        end_date: this.endDate,
        company_id: this.companyId,
        instruction_type: this.instructionType,
        fabric_type: this.fabricType,
        expense_id: this.expenseId,
        supplier_company_id: this.supplierCompanyId,
        order_id: this.orderId,
        user_id: this.userId,
      };
      if (this.fabricType) {
        let findItem = _.find(this.fabricModels, { id: +this.fabricType });
        filters.fabric_type = findItem.class_name;
      }
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "end_date", moment(this.endDate).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, "end_date", moment(this.endDate).format(LARAVEL_DATE_FORMAT));
      if (this.supplierCompanyId) {
        this.$set(filters, "supplier_company_id", JSON.stringify(this.supplierCompanyId));
      }
      if (this.companyId) {
        this.$set(filters, "company_id", JSON.stringify(this.companyId));
      }
      if (this.orderId) {
        this.$set(filters, "order_id", JSON.stringify(this.orderId));
      }
      if (this.userId) {
        this.$set(filters, "user_id", JSON.stringify(this.userId));
      }
      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/fire-report-chart-data",
        })
        .then((result) => {
          let netAmountData = [];
          let tmpData = result.data;
          let self = this;
          _.forEach(tmpData, function (amount, key) {
            self.chartCategories.push(key);
            let sumTotal = 0;

            sumTotal += Number(amount);
            self.chartText = "Toplam ";

            netAmountData.push(sumTotal.toFixed(4));
          });

          let tmpPackingSeries = {
            name: this.$t("general.total") + ": ",
            data: netAmountData,
          };
          this.series.push(tmpPackingSeries);
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        this.getItems();
      }
    },
  },
};
</script>
<style scoped>
.info-text-background {
  background-color: #f2f2f7;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-weight: 500;
}
.box {
  width: 15px;
  height: 15px;
}
.incerment-box {
  background-color: #f63535;
}
.plus-box {
  background-color: #357af6;
}
.graphic-container {
  overflow-x: scroll;
  display: flex;
  position: relative;
}
</style>
